export class CBChallengesComparison {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "CBCHALLENGESCOMPARISON";
    this.scene = "CBCHALLENGESCOMPARISON";
    this.data = [];
    this.page = 7059;
  }

  async preview({ options }) {
    this.onAir = false;
    let xml = await this.getVizPage({ page: this.page }).catch((err) => {
      console.log(err);
    });
    if (xml) {
      if (options.push_up) {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "1";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-312 0 0";
      } else {
        xml.payload.field.find((item) => {
          return item.attr.name === "0000";
        }).value = "0";
        xml.payload.field.find((item) => {
          return item.attr.name === "0001.position";
        }).value = "-343 0 0";
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "005";
      }).value =
        options.game?.home_team?.short_name +
        " v " +
        options.game?.away_team?.short_name;

      xml.payload.field.find((item) => {
        return item.attr.name === "006";
      }).value = options.game?.home_team?.code;

      xml.payload.field.find((item) => {
        return item.attr.name === "007";
      }).value = options.game?.away_team?.code;

      xml.payload.field.find((item) => {
        return item.attr.name === "015";
      }).value = options.game?.home_challenges?.successful ?? "0";
      xml.payload.field.find((item) => {
        return item.attr.name === "025";
      }).value = options.game?.home_challenges?.unsuccessful ?? "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "035";
      }).value = options.game?.home_challenges?.inconclusive ?? "0";

      xml.payload.field.find((item) => {
        return item.attr.name === "016";
      }).value = options.game?.away_challenges?.successful ?? "0";
      xml.payload.field.find((item) => {
        return item.attr.name === "026";
      }).value = options.game?.away_challenges?.unsuccessful ?? "0";
      xml.payload.field.find((item) => {
        return item.attr.name === "036";
      }).value = options.game?.away_challenges?.inconclusive ?? "0";

      await this.updateVizPage({ page: this.page, data: xml }).catch((err) => {
        console.log(err);
      });

      await this.sendPreview("page:read " + this.page);
    }
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({ page: this.page });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({ page: this.page });
  }
}
