import moment from "moment";
import { OtherTag } from "./OtherTag";
import { GoalTag } from "./GoalTag";

export class AllTag {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "AllTag";
    this.scene = "AllTag";
    this.data = [];
    this.downloadPage = 7007;
    this.page_first_half = 1250;
    this.page_second_half = 1270;
  }

  async preview({ options }) {
    this.onAir = false;
    this.page_first_half = 1250;
    this.page_second_half = 1270;
    let all_scorers = [
      ...([
        ...(options.game?.home_scorers?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }) || []),
      ] || []),
    ];

    let new_scorers = [
      ...([
        ...(options.game?.home_scorers?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }) || []),
      ] || []),
    ];

    let lineup = options.game?.lineup?.home;
    for (let x = 0; x < lineup?.length; x++) {
      for (let y = 0; y < lineup?.[x]?.sinbins?.length; y++) {
        new_scorers.push({
          ...lineup?.[x],
          ...lineup?.[x]?.sinbins?.[y],
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.sinbins?.[y]?.game_time_minutes,
            seconds: lineup?.[x]?.sinbins?.[y]?.game_time_seconds,
          }),
          type: "sin_bin",
        });
      }
      if (lineup?.[x]?.red_card) {
        new_scorers.push({
          ...lineup?.[x],
          game_time_minutes: lineup?.[x]?.red_card_time,
          game_time_seconds: lineup?.[x]?.red_card_seconds || 0,
          period: lineup?.[x]?.red_card_period,
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.red_card_time,
            seconds: lineup?.[x]?.red_card_seconds || 0,
          }),
          type: "red_card",
        });
      }
    }

    new_scorers = new_scorers.concat([
      ...([
        ...(options.game?.away_scorers?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }) || []),
      ] || []),
    ]);
    all_scorers = all_scorers.concat([
      ...([
        ...(options.game?.away_scorers?.map((s) => {
          return {
            ...s,
            time: moment.duration({
              minutes: s.game_time_minutes,
              seconds: s.game_time_seconds,
            }),
          };
        }) || []),
      ] || []),
    ]);
    lineup = options.game?.lineup?.away;
    for (let x = 0; x < lineup?.length; x++) {
      for (let y = 0; y < lineup?.[x]?.sinbins?.length; y++) {
        new_scorers.push({
          ...lineup?.[x],
          ...lineup?.[x]?.sinbins?.[y],
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.sinbins?.[y]?.game_time_minutes,
            seconds: lineup?.[x]?.sinbins?.[y]?.game_time_seconds,
          }),
          type: "sin_bin",
        });
      }
      if (lineup?.[x]?.red_card) {
        new_scorers.push({
          ...lineup?.[x],
          game_time_minutes: lineup?.[x]?.red_card_time,
          game_time_seconds: lineup?.[x]?.red_card_seconds || 0,
          period: lineup?.[x]?.red_card_period,
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.red_card_time,
            seconds: lineup?.[x]?.red_card_seconds || 0,
          }),
          type: "red_card",
        });
      }
    }

    // new_scorers = new_scorers?.filter((s) => s.period === options.half);

    new_scorers = new_scorers?.sort((a, b) => {
      if (a?.period < b?.period) {
        return -1;
      } else if (a?.period > b?.period) {
        return 1;
      }
      if (a?.game_time_minutes < b?.game_time_minutes) {
        return -1;
      } else if (a?.game_time_minutes > b?.game_time_minutes) {
        return 1;
      }
      if (a?.game_time_seconds < b?.game_time_seconds) {
        return -1;
      } else if (a?.game_time_seconds > b?.game_time_seconds) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < new_scorers.length; i++) {
      let ev = new_scorers?.[i];
      let player = options.homeLineup.find((p) => p.squad?._id === ev.player);

      if (!player) {
        player = options.awayLineup.find((p) => p.squad?._id === ev.player);
        if (
          options.away_squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          player = JSON.parse(JSON.stringify(player));
          player.squad.player.last_name =
            player?.squad?.player?.first_name_upper?.[0] +
            ". " +
            player.squad.player.last_name;
        }
      } else {
        if (
          options.home_squad?.filter(
            (s) =>
              s?.player?.last_name_upper ===
                player?.squad?.player?.last_name_upper &&
              s?.type === "player" &&
              s?.active
          )?.length > 1
        ) {
          player = JSON.parse(JSON.stringify(player));
          player.squad.player.last_name =
            player?.squad?.player?.first_name_upper?.[0] +
            ". " +
            player.squad.player.last_name;
        }
      }

      let amount = all_scorers.filter((s) => {
        return (
          s?.type === ev?.type &&
          s.player === ev.player &&
          s.period <= ev.period &&
          (s.period < ev.period ||
            s.time?.asMilliseconds() <= ev.time?.asMilliseconds())
        );
      });
      let count = amount?.length || 0;
      if (
        ev.type === "sin_bin" ||
        ev.type === "drop_goal" ||
        ev.type === "red_card"
      ) {
        let tag = new OtherTag({
          animate: this.sendAnimate,
          preview: this.sendPreview,
          update: this.sendUpdate,
          animateOff: this.sendAnimateOff,
          getVizPage: this.getVizPage,
          updateVizPage: this.updateVizPage,
          vizSharedMemory: this.vizSharedMemory,
          createPage: this.createPage,
          setPageSummary: this.setPageSummary,
        });
        if (ev.period === 1) {
          tag.page = this.page_first_half;
          this.page_first_half = this.page_first_half + 1;
        } else {
          tag.page = this.page_second_half;
          this.page_second_half = this.page_second_half + 1;
        }

        await tag.buildSingle({
          game: options.game,
          goal: ev,
          player: player,
          build: true,
          count: count,
          total: new_scorers.filter(
            (s) => s.player === ev.player && s?.type === ev?.type
          ).length,
          type: ev?.type,
        });
      } else if (ev.type === "try") {
        let tag = new GoalTag({
          animate: this.sendAnimate,
          preview: this.sendPreview,
          update: this.sendUpdate,
          animateOff: this.sendAnimateOff,
          getVizPage: this.getVizPage,
          updateVizPage: this.updateVizPage,
          vizSharedMemory: this.vizSharedMemory,
          createPage: this.createPage,
          setPageSummary: this.setPageSummary,
        });
        if (ev.period === 1) {
          tag.page = this.page_first_half;
          this.page_first_half = this.page_first_half + 1;
        } else {
          tag.page = this.page_second_half;
          this.page_second_half = this.page_second_half + 1;
        }
        await tag.buildSingle({
          game: options.game,
          goal: ev,
          player: player,
          build: true,
          count: count,
          total: new_scorers.filter(
            (s) => s.player === ev.player && s?.type === ev?.type
          ).length,
          type: ev?.type,
        });
      } else if (ev.type === "no_try") {
        let tag = new GoalTag({
          animate: this.sendAnimate,
          preview: this.sendPreview,
          update: this.sendUpdate,
          animateOff: this.sendAnimateOff,
          getVizPage: this.getVizPage,
          updateVizPage: this.updateVizPage,
          vizSharedMemory: this.vizSharedMemory,
          createPage: this.createPage,
          setPageSummary: this.setPageSummary,
        });
        if (ev.period === 1) {
          tag.page = this.page_first_half;
          this.page_first_half = this.page_first_half + 1;
        } else {
          tag.page = this.page_second_half;
          this.page_second_half = this.page_second_half + 1;
        }
        await tag.buildSingle({
          game: options.game,
          goal: ev,
          player: player,
          build: true,
          count: count,
          total: new_scorers.filter(
            (s) => s.player === ev.player && s?.type === ev?.type
          ).length,
          type: ev?.type,
        });
      }
    }
  }

  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
