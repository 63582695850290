export class TeamCrawl {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "TeamCrawl";
    this.scene = "TeamCrawl";
    this.data = [];
    this.download_page_home = 7110;
    this.download_page_away = 7111;
    this.home_page = 6951;
    this.home_page_other = 6961;
    this.away_page = 6952;
    this.away_page_other = 6962;
    this.preview_page = 6950;
    this.preview_page_other = 6960;

    this.positions = [
      "FB",
      "RW",
      "RC",
      "LC",
      "LW",
      "SO",
      "SH",
      "PR",
      "HK",
      "PR",
      "SR",
      "SR",
      "LF",
    ];
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];

    if (options?.game?.home_team?._id === options?.team?._id) {
      this.page = options.other ? this.home_page_other : this.home_page;
      this.download_page = this.download_page_home;
    } else if (options?.game?.away_team?._id === options?.team?._id) {
      this.page = options.other ? this.away_page_other : this.away_page;
      this.download_page = this.download_page_away;
    }

    let xml = await this.getVizPage({ page: this.download_page });

    if (xml) {
      xml.payload.field.find((item) => {
        return item.attr.name === "601";
      }).value = options?.team?.short_name;

      let crawl_text = "";
      let loop = new Array(13).fill(1);
      let number_of_captains = options.squads?.filter((s) => s.captain)?.length;
      for (let index = 0; index < loop.length; index++) {
        let row = index + 1;

        let player;
        if (
          row <=
          (options?.game?.competition?.variables?.number_of_players_on_field ??
            13)
        ) {
          player = options.squads?.find((s) => s.order === row);

          if (
            !options?.game?.competition?.variables
              ?.number_of_players_on_field ||
            options?.game?.competition?.variables?.number_of_players_on_field >
              5
          ) {
            crawl_text += this.positions[index];
            crawl_text += ": ";
          }

          crawl_text += player?.squad?.shirt_number || "";
          crawl_text += ". ";
          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    player?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    player?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              crawl_text += player?.squad?.player?.first_name + " ";
            } else {
              crawl_text += player?.squad?.player?.first_name?.[0] + ". ";
            }
          }
          crawl_text +=
            player?.squad?.player?.last_name +
              (player?.captain
                ? number_of_captains === 1
                  ? " (c)"
                  : " (cc)"
                : "") || "";

          crawl_text += player?.debut ? " (D)" : "";

          if (player?.subbed) {
            crawl_text += " &gt;";
          }
          crawl_text += "     ";
        }
      }
      crawl_text += "     Interchanges:     ";
      loop = new Array(5).fill(1);
      for (let index = 0; index < loop.length; index++) {
        let row = index + 1;
        let tab_row = "6" + row;
        if (
          !options?.game?.competition?.variables?.number_of_players_on_field ||
          options?.game?.competition?.variables?.number_of_players_on_field > 5
        ) {
          if (index === 4) {
            crawl_text += "18th Player:     ";
          }
        }
        let player = options.squads?.find(
          (s) =>
            s.order ===
            row +
              (options?.game?.competition?.variables
                ?.number_of_players_on_field ?? 13)
        );

        if (player) {
          crawl_text += player?.squad?.shirt_number || "";
          crawl_text += ". ";

          if (
            options.squad?.filter(
              (s) =>
                s?.player?.last_name_upper ===
                  player?.squad?.player?.last_name_upper &&
                s?.type === "player" &&
                s?.active
            )?.length > 1
          ) {
            if (
              options.squad?.filter(
                (s) =>
                  s?.player?.last_name_upper ===
                    player?.squad?.player?.last_name_upper &&
                  s?.player?.first_name_upper?.[0] ===
                    player?.squad?.player?.first_name_upper?.[0] &&
                  s?.type === "player" &&
                  s?.active
              )?.length > 1
            ) {
              crawl_text += player?.squad?.player?.first_name + " ";
            } else {
              crawl_text += player?.squad?.player?.first_name?.[0] + ". ";
            }
          }

          crawl_text +=
            player?.squad?.player?.last_name +
              (player?.captain
                ? number_of_captains === 1
                  ? " (c)"
                  : " (cc)"
                : "") || "";
          crawl_text += player?.debut ? " (D)" : "";
          if (player?.subbed) {
            crawl_text += " &lt;";
          }
          crawl_text += "     ";
        }
      }

      xml.payload.field.find((item) => {
        return item.attr.name === "620";
      }).value = crawl_text;
    }

    await this.updateVizPage({
      page: this.page,
      data: xml,
      escape: false,
    }).catch((err) => {
      console.log(err);
    });

    await this.sendPreview(
      "page:read " +
        (options.other ? this.preview_page_other : this.preview_page)
    );
  }
  animate({ options }) {
    this.onAir = true;
    this.sendAnimate({
      page: options.other ? this.preview_page_other : this.preview_page,
    });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff({
      page: options.other ? this.preview_page_other : this.preview_page,
    });
  }
}
