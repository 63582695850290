export class Timeline {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Timeline";
    this.scene = "Timeline";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    let events = options.data?.commentary?.filter(
      (c) => c.type?.name !== "substitution"
    );

    new Array(24).fill(1)?.map((_, index) => {
      let row = index + 1;
      let event = events?.[index];
      this.data.push({
        name: "Event Type " + row,
        value: {
          text: event?.type?.name || "",
        },
      });
      this.data.push({
        name: "Event Team " + row,
        value: {
          text:
            event?.team?.id === options.game?.home_team?.oval_ID
              ? "home"
              : event?.team?.id === options.game?.away_team?.oval_ID
              ? "away"
              : "",
        },
      });
      this.data.push({
        name: "Event Time " + row,
        value: {
          text: event?.time || "",
        },
      });
      this.data.push({
        name: "Event Time Minute " + row,
        value: {
          text: event?.minute || "",
        },
      });
      this.data.push({
        name: "Event Time Second " + row,
        value: {
          text: event?.second || "",
        },
      });
      this.data.push({
        name: "Event Period " + row,
        value: {
          text:
            event?.period?.id === 20 ? 1 : event?.period?.id === 30 ? 2 : "",
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
