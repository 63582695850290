const UNION_POSITIONS = [
  "LOOSEHEAD PROP",
  "HOOKER",
  "TIGHTHEAD PROP",
  "SECOND ROW",
  "SECOND ROW",
  "FLANKER",
  "FLANKER",
  "NUMBER EIGHT",
  "SCRUM HALF",
  "FLY HALF",
  "LEFT WING",
  "INSIDE CENTRE",
  "OUTSIDE CENTRE",
  "RIGHT WING",
  "FULL BACK",
];

export const LEAGUE_POSITIONS = [
  "FB",
  "RW",
  "RC",
  "LC",
  "LW",
  "SO",
  "SH",
  "PR 1",
  "HK",
  "PR 2",
  "SR 1",
  "SR 2",
  "LF",
];

export function getPosition({ order }) {
  return UNION_POSITIONS?.[order - 1] ?? "REPLACEMENT";
}
export function getLeaguePosition({ order }) {
  return LEAGUE_POSITIONS?.[order - 1] ?? "INTERCHANGE";
}
