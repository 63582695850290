import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button, ScrollBar } from "../../Theme/Hyper";
import Select from "react-select";
import { GraphicsContext } from "../../contexts/GraphicsContext";
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const names = [
  {
    id: "attacking_kicks",
    name: "Attacking kicks",
  },
  {
    id: "av_gain",
    name: "Average gain",
  },
  {
    id: "carries",
    name: "Carries",
  },
  {
    id: "clean_break",
    name: "Linebreaks",
  },
  {
    id: "drop_goals",
    name: "Drop Goals",
  },
  {
    id: "errors",
    name: "Errors",
  },
  {
    id: "forty_twenty",
    name: "40/20s",
  },
  {
    id: "game_id",
    name: "",
  },
  {
    id: "goals",
    name: "",
  },
  {
    id: "kicks_in_gen_play",
    name: "Kicks in general play",
  },
  {
    id: "marker_tackles",
    name: "Marker tackles",
  },
  {
    id: "metres",
    name: "Metres",
  },
  {
    id: "missed_goals",
    name: "",
  },
  {
    id: "missed_tackles",
    name: "Missed tackles",
  },
  {
    id: "offload",
    name: "Offloads",
  },
  {
    id: "penalties",
    name: "Penalties conceded",
  },
  {
    id: "player_id",
    name: "",
  },
  {
    id: "red_card",
    name: "",
  },
  {
    id: "run_from_dummy_half",
    name: "Runs from dummy half",
  },
  {
    id: "tackle_busts",
    name: "Tackle busts",
  },
  {
    id: "tackles",
    name: "Tackles",
  },
  {
    id: "team_id",
    name: "",
  },
  {
    id: "tries",
    name: "Tries",
  },
  {
    id: "try_assists",
    name: "Try assists",
  },
  {
    id: "yellow_card",
    name: "",
  },
  {
    id: "break_assist",
    name: "Linebreak assists",
  },
  {
    id: "conversions",
    name: "Conversions",
  },
  {
    id: "dummy_half_metres",
    name: "Dummy half metres",
  },
  {
    id: "hit_up_metres",
    name: "Hit up metres",
  },
  {
    id: "hit_ups",
    name: "Hit ups",
  },
  {
    id: "kick_from_hand_metres",
    name: "Kick from hand metres",
  },
  {
    id: "kick_return_metres",
    name: "Kick return metres",
  },
  {
    id: "kick_returns",
    name: "Kick returns",
  },
  {
    id: "minutes_player_total",
    name: "Minutes",
  },
  {
    id: "missed_conversions",
    name: "",
  },
  {
    id: "missed_penalty_goals",
    name: "",
  },
  {
    id: "on_report",
    name: "",
  },
  {
    id: "passes",
    name: "Passes",
  },
  {
    id: "penalty_goals",
    name: "",
  },
  {
    id: "twenty_forty",
    name: "20/40s",
  },
  {
    id: "ruck_infringements",
    name: "Ruck infringements",
  },
  {
    id: "set_restart_conceded_10m_offside",
    name: "Six agains conceded (10m offside)",
  },
  {
    id: "set_restart_won",
    name: "Six agains won",
  },
  {
    id: "kick_defused",
    name: "Kicks defused",
  },
  {
    id: "kick_not_defused",
    name: "Kicks not defused",
  },
  {
    id: "ball_run_metres",
    name: "Carry metres",
  },
  {
    id: "post_contact_metres",
    name: "Post contact metres",
  },
  {
    id: "one_on_one_steal",
    name: "One-on-one steal",
  },
  {
    id: "one_point_field_goal",
    name: "",
  },
  {
    id: "two_point_field_goal",
    name: "",
  },
  {
    id: "tackle_efficiency",
    name: "Tackle success",
  },
  {
    id: "goal_line_dropout",
    name: "Goal line dropouts",
  },
  {
    id: "missed_field_goals",
    name: "",
  },
  {
    id: "ptb_opp_20m",
    name: "PTBs in opp. 20",
  },
  {
    id: "kick_receipt",
    name: "Kick receipts",
  },
  {
    id: "minutes_played_total",
    name: "Minutes played",
  },
  {
    id: "top_scorers",
    name: "Point scorers",
  },
];

const manual_logging_names = [
  {
    id: "Carries",
    name: "Carries",
  },
  {
    id: "Breaks",
    name: "Breaks",
  },
  {
    id: "Handling errors",
    name: "Errors",
  },
  {
    id: "Metres",
    name: "Metres",
  },
  {
    id: "Missed Tackles",
    name: "Missed tackles",
  },
  {
    id: "Offloads",
    name: "Offloads",
  },
  {
    id: "Tackles",
    name: "Tackles",
  },
  {
    id: "top_scorers",
    name: "Point scorers",
  },
];

const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 700px;
  max-height: 739px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#1c222b",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",
    borderColor: "#384454",
    boxShadow: "",
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#1c222b",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};
function TopPerformers({ game, previewGraphic }) {
  return game?.competition?.variables?.logging_type === "manual" ? (
    <TopPerformersManual game={game} previewGraphic={previewGraphic} />
  ) : (
    <TopPerformersOPTA game={game} previewGraphic={previewGraphic} />
  );
}
function TopPerformersOPTA({ game, previewGraphic }) {
  const [selectedStat, setSelectedStat] = useState();
  const { graphics } = useContext(GraphicsContext);
  return (
    <Main>
      <SelectContainer
        styles={DropdownStyles}
        setSize={true}
        value={{
          value: selectedStat,
          label: names?.find((e) => e.id === selectedStat)?.name,
        }}
        options={names
          ?.filter((s) => s?.name && s?.name !== "")
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map((item, index) => {
            return {
              value: item.id,
              label: item.name,
            };
          })}
        onChange={(e) => {
          setSelectedStat(e.value);
        }}
        placeholder="Stat"
      />
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          category={names?.find((e) => e.id === selectedStat)?.name}
          subline={"Top {top}"}
          players={game?.opta_home_player_stats
            ?.concat(game?.opta_away_player_stats)
            ?.filter(
              (s) => s?.stats?.[selectedStat] && s?.stats?.[selectedStat] > 0
            )
            ?.sort((a, b) => {
              return b?.stats?.[selectedStat] - a?.stats?.[selectedStat];
            })
            ?.map((player) => {
              return {
                ...game?.lineup?.home?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...game?.lineup?.away?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...player,
                team: game?.lineup?.home?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                )
                  ? game?.home_team
                  : game?.lineup?.away?.find(
                      (p) => p?.squad?.player?.opta_ID === player?.player
                    )
                  ? game?.away_team
                  : null,
              };
            })}
          previewGraphic={previewGraphic}
          top={5}
          graphics={graphics}
        />
      )}
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={game?.home_team?.short_name + " top {top}"}
          category={names?.find((e) => e.id === selectedStat)?.name}
          subline={game?.home_team?.short_name + " top {top}"}
          stat_key={selectedStat}
          players={[...(game?.opta_home_player_stats || [])]
            ?.filter(
              (s) => s?.stats?.[selectedStat] && s?.stats?.[selectedStat] > 0
            )
            ?.sort((a, b) => {
              return b?.stats?.[selectedStat] - a?.stats?.[selectedStat];
            })

            ?.map((player) => {
              return {
                ...game?.lineup?.home?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...game?.lineup?.away?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...player,
                team: game?.home_team,
              };
            })}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.home_team}
          top={3}
          graphics={graphics}
        />
      )}
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={game?.away_team?.short_name + " top {top}"}
          subline={game?.away_team?.short_name + " top {top}"}
          category={names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          players={[...(game?.opta_away_player_stats || [])]
            ?.filter(
              (s) => s?.stats?.[selectedStat] && s?.stats?.[selectedStat] > 0
            )
            ?.sort((a, b) => {
              return b?.stats?.[selectedStat] - a?.stats?.[selectedStat];
            })

            ?.map((player) => {
              return {
                ...game?.lineup?.home?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...game?.lineup?.away?.find(
                  (p) => p?.squad?.player?.opta_ID === player?.player
                ),
                ...player,
                team: game?.away_team,
              };
            })}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.away_team}
          top={3}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={names?.find((e) => e.id === selectedStat)?.name}
          subline={"Top {top}"}
          category={names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={false}
          players={game?.lineup?.home
            ?.concat(game?.lineup?.away)
            ?.map((player) => {
              return {
                ...player,
                team: game?.lineup?.home?.find(
                  (p) =>
                    p?.squad?.player?.opta_ID === player?.squad?.player?.opta_ID
                )
                  ? game?.home_team
                  : game?.lineup?.away?.find(
                      (p) =>
                        p?.squad?.player?.opta_ID ===
                        player?.squad?.player?.opta_ID
                    )
                  ? game?.away_team
                  : null,
              };
            })}
          scorers={game?.home_scorers?.concat(game?.away_scorers)}
          top={5}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={game?.home_team?.short_name + " top {top}"}
          subline={game?.home_team?.short_name + " top {top}"}
          category={names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.home_team}
          players={game?.lineup?.home}
          scorers={game?.home_scorers}
          top={3}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={game?.away_team?.short_name + " top {top}"}
          subline={game?.away_team?.short_name + " top {top}"}
          category={names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.away_team}
          players={game?.lineup?.away}
          scorers={game?.away_scorers}
          top={3}
          graphics={graphics}
        />
      )}
    </Main>
  );
}
function TopPerformersManual({ game, previewGraphic }) {
  const [selectedStat, setSelectedStat] = useState();
  const { graphics } = useContext(GraphicsContext);
  return (
    <Main>
      <SelectContainer
        styles={DropdownStyles}
        setSize={true}
        value={{
          value: selectedStat,
          label: manual_logging_names?.find((e) => e.id === selectedStat)?.name,
        }}
        options={manual_logging_names
          ?.filter((s) => s?.name && s?.name !== "")
          ?.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          ?.map((item, index) => {
            return {
              value: item.id,
              label: item.name,
            };
          })}
        onChange={(e) => {
          setSelectedStat(e.value);
        }}
        placeholder="Stat"
      />
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={manual_logging_names?.find((e) => e.id === selectedStat)?.name}
          stat_key={selectedStat}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          subline={"Top {top}"}
          players={Object.keys(game?.home_player_stats || {})
            ?.map((player) => {
              return {
                ...game?.home_player_stats[player],
                stats: { ...game?.home_player_stats[player] },
                ...game?.lineup?.home?.find((p) => p?.squad?._id === player),
                ...game?.lineup?.away?.find((p) => p?.squad?._id === player),
                team: game?.lineup?.home?.find((p) => p?.squad?._id === player)
                  ? game?.home_team
                  : game?.lineup?.away?.find((p) => p?.squad?._id === player)
                  ? game?.away_team
                  : null,
              };
            })
            ?.concat(
              Object.keys(game?.away_player_stats || {})?.map((player) => {
                return {
                  ...game?.away_player_stats[player],
                  stats: { ...game?.away_player_stats[player] },
                  ...game?.lineup?.home?.find((p) => p?.squad?._id === player),
                  ...game?.lineup?.away?.find((p) => p?.squad?._id === player),

                  team: game?.lineup?.home?.find(
                    (p) => p?.squad?._id === player
                  )
                    ? game?.home_team
                    : game?.lineup?.away?.find((p) => p?.squad?._id === player)
                    ? game?.away_team
                    : null,
                };
              })
            )
            ?.filter((s) => s?.[selectedStat] && s?.[selectedStat] > 0)
            ?.sort((a, b) => {
              return b?.[selectedStat] - a?.[selectedStat];
            })}
          previewGraphic={previewGraphic}
          top={5}
          graphics={graphics}
        />
      )}
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={game?.home_team?.short_name + " top {top}"}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          subline={game?.home_team?.short_name + " top {top}"}
          stat_key={selectedStat}
          players={Object.keys(game?.home_player_stats || {})
            ?.map((player) => {
              return {
                ...game?.home_player_stats[player],
                stats: { ...game?.home_player_stats[player] },
                ...game?.lineup?.home?.find((p) => p?.squad?._id === player),
                ...game?.lineup?.away?.find((p) => p?.squad?._id === player),
                team: game?.lineup?.home?.find((p) => p?.squad?._id === player)
                  ? game?.home_team
                  : game?.lineup?.away?.find((p) => p?.squad?._id === player)
                  ? game?.away_team
                  : null,
              };
            })
            ?.filter((s) => s?.[selectedStat] && s?.[selectedStat] > 0)
            ?.sort((a, b) => {
              return b?.[selectedStat] - a?.[selectedStat];
            })}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.home_team}
          top={3}
          graphics={graphics}
        />
      )}
      {selectedStat && selectedStat !== "top_scorers" && (
        <Category
          title={game?.away_team?.short_name + " top {top}"}
          subline={game?.away_team?.short_name + " top {top}"}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          stat_key={selectedStat}
          players={Object.keys(game?.away_player_stats || {})
            ?.map((player) => {
              return {
                ...game?.away_player_stats[player],
                stats: { ...game?.away_player_stats[player] },
                ...game?.lineup?.home?.find((p) => p?.squad?._id === player),
                ...game?.lineup?.away?.find((p) => p?.squad?._id === player),
                team: game?.lineup?.home?.find((p) => p?.squad?._id === player)
                  ? game?.home_team
                  : game?.lineup?.away?.find((p) => p?.squad?._id === player)
                  ? game?.away_team
                  : null,
              };
            })
            ?.filter((s) => s?.[selectedStat] && s?.[selectedStat] > 0)
            ?.sort((a, b) => {
              return b?.[selectedStat] - a?.[selectedStat];
            })}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.away_team}
          top={3}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={manual_logging_names?.find((e) => e.id === selectedStat)?.name}
          subline={"Top {top}"}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={false}
          players={game?.lineup?.home
            ?.concat(game?.lineup?.away)
            ?.map((player) => {
              return {
                ...player,
                team: game?.lineup?.home?.find(
                  (p) => p?.squad?._id === player?.squad?._id
                )
                  ? game?.home_team
                  : game?.lineup?.away?.find(
                      (p) => p?.squad?._id === player?.squad?._id
                    )
                  ? game?.away_team
                  : null,
              };
            })}
          scorers={game?.home_scorers?.concat(game?.away_scorers)}
          top={5}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={game?.home_team?.short_name + " top {top}"}
          subline={game?.home_team?.short_name + " top {top}"}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.home_team}
          players={game?.lineup?.home}
          scorers={game?.home_scorers}
          top={3}
          graphics={graphics}
        />
      )}

      {selectedStat === "top_scorers" && (
        <TopScorers
          title={game?.away_team?.short_name + " top {top}"}
          subline={game?.away_team?.short_name + " top {top}"}
          category={
            manual_logging_names?.find((e) => e.id === selectedStat)?.name
          }
          stat_key={selectedStat}
          previewGraphic={previewGraphic}
          team_cb={true}
          team={game?.away_team}
          players={game?.lineup?.away}
          scorers={game?.away_scorers}
          top={3}
          graphics={graphics}
        />
      )}
    </Main>
  );
}
const StatMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  background-color: #1c222b;
  border-bottom: 2px solid #202731;
  padding: 0.5em;
  margin-bottom: 5px;
`;

const Row = styled.div`
  display: flex;
  padding: 0.2em;
`;
const Name = styled.div`
  display: flex;
  min-width: 140px;
`;
const Value = styled.div`
  display: flex;
  min-width: 60px;
`;
const Team = styled.div`
  display: flex;
  min-width: 60px;
`;
function Category({
  title,
  subline,
  category,
  players,
  stat_key,
  previewGraphic,
  team_cb,
  team,
  top,
  graphics,
}) {
  return (
    <StatMain>
      <div style={{ display: "flex" }}>
        {title?.replace("{top}", top)}
        <div style={{ marginLeft: "auto" }}>{players?.length}</div>
      </div>
      {players?.slice(0, top)?.map((player) => {
        return (
          <Row>
            <Name>{player?.squad?.player?.last_name}</Name>
            <Value>{parseInt(player?.stats?.[stat_key])}</Value>
            <Team>{player?.team?.code}</Team>
          </Row>
        );
      })}
      <GraphicButton
        onClick={() => {
          previewGraphic("TopPerformers", {
            title: category,
            subline: subline?.replace("{top}", Math.min(players?.length, top)),
            players: players?.slice(0, top),
            stat_key,
            team_cb,
            team,
            total_players: players?.length,
            top,
            push_up:
              graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
              graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
          });
        }}
      >
        Build CB
      </GraphicButton>
    </StatMain>
  );
}

function TopScorers({
  title,
  subline,
  category,
  stat_key,
  previewGraphic,
  team_cb,
  team,
  players,
  scorers,
  top,
  graphics,
}) {
  let calc_players = players
    ?.map((player) => {
      let tries =
        (scorers?.filter(
          (s) => s?.player === player?.squad?._id && s.type === "try"
        )?.length || 0) * 4;
      let conv =
        (scorers?.filter(
          (s) =>
            s?.player === player?.squad?._id &&
            (s.type === "conversion" || s?.type === "penalty_kick")
        )?.length || 0) * 2;
      let dg =
        scorers?.filter(
          (s) => s?.player === player?.squad?._id && s.type === "drop_goal"
        )?.length || 0;

      return { ...player, stats: { [stat_key]: tries + conv + dg } };
    })
    ?.filter((p) => p?.stats?.[stat_key] > 0)
    ?.sort((a, b) => {
      return b?.stats?.[stat_key] - a?.stats?.[stat_key];
    });

  return (
    <StatMain>
      <div style={{ display: "flex" }}>
        {title?.replace("{top}", top)}
        <div style={{ marginLeft: "auto" }}>{calc_players?.length}</div>
      </div>

      {calc_players?.slice(0, top)?.map((player) => {
        return (
          <Row>
            <Name>{player?.squad?.player?.last_name}</Name>
            <Value>{player?.stats?.[stat_key]}</Value>
            <Team>{player?.team?.code}</Team>
          </Row>
        );
      })}
      <GraphicButton
        onClick={() => {
          previewGraphic("TopPerformers", {
            title: category,
            subline: subline?.replace(
              "{top}",
              calc_players?.slice(0, top)?.length
            ),
            players: calc_players?.slice(0, top),
            stat_key,
            team_cb,
            team,
            total_players: calc_players?.length,
            top,
            push_up:
              graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
              graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
          });
        }}
      >
        Build CB
      </GraphicButton>
    </StatMain>
  );
}
export default TopPerformers;
