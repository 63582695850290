import moment from "moment";

export class GoalTag {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
    createPage,
    setPageSummary,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.createPage = createPage;
    this.setPageSummary = setPageSummary;
    this.name = "GoalTag";
    this.scene = "GoalTag";
    this.data = [];
    this.downloadPage = 7007;
    this.page = 1251;
    this.buildPage = 1251;
  }

  async preview({ options }) {
    this.onAir = false;

    if (!options.build) {
      this.page = this.downloadPage;
      await this.buildSingle(options);
      await this.sendPreview("page:read " + this.page);
    } else {
      this.page = this.buildPage;
      await this.buildAll(options);
      this.page = this.buildPage;
      await this.sendPreview("page:read " + this.page);
    }
  }

  async buildSingle(options) {
    let xml = await this.getVizPage({ page: this.downloadPage });

    let goalTime =
      options?.goal?.period === 1
        ? Math.min(40, options?.goal?.game_time_minutes + 1)
        : options?.goal?.period === 2
        ? Math.min(80, options?.goal?.game_time_minutes + 1)
        : options?.goal?.game_time_minutes + 1;

    if (options?.goal?.game_time_minutes === 0) {
      goalTime = options?.goal?.game_time_seconds + " secs";
    } else {
      goalTime += goalTime > 1 ? " mins" : " min";
    }

    xml.payload.field.find((item) => {
      return item.attr.name === "103"; // home team
    }).value =
      options.player?.squad?.player?.last_name +
      (options.type === "no_try"
        ? (options.total > 1
            ? " " + this.ordinal_suffix_of(options.count)
            : "") + " no try"
        : (options.count === 3
            ? " hat-trick"
            : options.total > 1
            ? " " + this.ordinal_suffix_of(options.count)
            : "") + (options.count !== 3 ? " try" : "")); // needs to be {goalScorer},'goal',{goalTime},'min/mins')
    xml.payload.field.find((item) => {
      return item.attr.name === "122"; // home team
    }).value = goalTime;

    if (options.build) {
      await this.updateVizPage({ page: this.page, data: xml }).then(
        async (data) => {
          if (data === "") {
            await this.createPage({
              page: this.page,
              data: xml,
            });
          }
          this.setPageSummary({
            page: this.page,
            summary:
              options.player?.squad?.player?.last_name +
              (options.type === "no_try"
                ? " no try"
                : (options.count === 3
                    ? " hat-trick"
                    : " " + options.total > 1
                    ? " " + this.ordinal_suffix_of(options.count)
                    : "") + (options.count !== 3 ? " try" : "")) +
              " - " +
              (options?.goal?.period < 3
                ? this.ordinal_suffix_of(options?.goal?.period) + " Period "
                : options?.goal?.period === 3
                ? "GP1 "
                : options?.goal?.period === 4
                ? "GP2 "
                : "") +
              options?.goal?.game_time_minutes?.toString()?.padStart(2, "0") +
              ":" +
              options?.goal?.game_time_seconds?.toString()?.padStart(2, "0"),
          });
        }
      );
    } else {
      await this.updateVizPage({ page: this.page, data: xml });
    }
  }

  async buildAll(options) {
    let scorers = [
      ...options.game.home_scorers?.filter((t) => t.type === "try"),
      ...options.game.away_scorers?.filter((t) => t.type === "try"),
    ];

    scorers = scorers.sort((a, b) => {
      if (a.period < b.period) {
        return -1;
      }
      if (a.game_time_minutes < b.game_time_minutes) {
        return -1;
      }
      return 1;
    });
    for (let i = 0; i < scorers.length; i++) {
      let goal = scorers[i];
      this.page = this.buildPage + i;
      let time = moment.duration({
        minutes: goal.game_time_minutes,
        seconds: goal.game_time_seconds,
      });

      let player = options.homeLineup.find((p) => p.squad?._id === goal.player);
      if (!player) {
        player = options.awayLineup.find((p) => p.squad?._id === goal.player);
      }

      let amount = scorers.filter(
        (s) =>
          s.player === goal.player &&
          (s.period < goal.period || s.time <= goal.time)
      );

      let count = amount?.length || 0;
      await this.buildSingle({
        game: options.game,
        goal: goal,
        player: player,
        time: time.minutes() + time.hours() * 60,
        seconds: time.seconds(),
        build: true,
        count: count,
        total: scorers.filter((s) => s.player === goal.player).length,
      });
    }
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  animate({ options }) {
    this.viz.take(this.page);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.viz.takeOut(this.page);
  }
}
