import { Name } from "./Name";
import { Clock } from "./Clock";
import { Potm } from "./POTM";
import { LTSinBin } from "./LTSinBin";
import { ClockTeamStat } from "./ClockTeamStat";
import { LTRedCard } from "./LTRedCard";
import { LTOnReport } from "./LTOnReport";
import { LTPlayerScored } from "./LTPlayerScored";
import { LTPlayerStat } from "./LTPlayerStat";
import { Substitution } from "./Substitution";
import { TwoSubstitution } from "./TwoSubstitution";
import { ThreeSubstitution } from "./ThreeSubstitution";
import { FormationSOF } from "./FormationSOF";
import { FFStats } from "./FFStats.js";
import { FFScore } from "./FFScore.js";
import { Versus } from "./Versus.js";
import { Formation } from "./Formation.js";
import { TeamCrawl } from "./TeamCrawl.js";
import { TeamBottomBar } from "./TeamBottomBar.js";
import { TopPerformers } from "./TopPerformers.js";
import { OnBench } from "./OnBench.js";
import { MatchStats } from "./MatchStats.js";
import { PositionMatchStats } from "./PositionMatchStats.js";
import { EdgeMatchStats } from "./EdgeMatchStats.js";
import { GoalTag } from "./GoalTag.js";
import { OtherTag } from "./OtherTag.js";
import { GameScoresTag } from "./GameScoresTag.js";
import { NameTag } from "./NameTag.js";
import { LatestScoresCB } from "./LatestScoresCB.js";
import { FFTable } from "./FFTable.js";
import { SOFTable } from "./SOFTable.js";
import { CBTable } from "./CBTable.js";
import { GameScoresBug } from "./GameScoresBug.js";
import { ScoreBug } from "./ScoreBug.js";
import { LTScore } from "./LTScore.js";
import { ClockTryTimeline } from "./ClockTryTimeline.js";
import { CBSinBin } from "./CBSinBin.js";
import { PlayerCB } from "./PlayerCB.js";
import { AllTag } from "./AllTag.js";
import { VoiceOf } from "./VoiceOf.js";
import { GuestName } from "./GuestName.js";
import { FFStatsLoggers } from "./FFStatsLoggers.js";
import { CarryingStats } from "./CarryingStats.js";
import { AttackingStats } from "./AttackingStats.js";
import { DefendingStats } from "./DefendingStats.js";
import { KickPredictorGauge } from "./KickPredictorGauge.js";
import { PlayerKeyStats } from "./PlayerKeyStats.js";
import { CBSeasonStats } from "./CBSeasonStats.js";
import { CBManual } from "./CBManual.js";
import { CBChallenges } from "./CBChallenges.js";
import { HeroFormation } from "./HeroFormation.js";
import { CBChallengesComparison } from "./CBChallengesComparison.js";
import { ShotClock } from "./ShotClock.js";

export default function graphics({
  takeVizPage: animate,
  update,
  takeOutVizPage: animateOff,
  triggerAnimation,
  clearPreview,
  previewTrio: preview,
  getVizPage,
  updateVizPage,
  vizSharedMemory,
  vizCommand,
  createPage,
  setPageSummary,
  continueVizPage,
}) {
  return [
    new Name({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new Clock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      vizCommand,
    }),
    new Potm({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new LTSinBin({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new ClockTeamStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new LTRedCard({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new LTOnReport({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new LTPlayerScored({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new LTPlayerStat({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new Substitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new TwoSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new ThreeSubstitution({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new FormationSOF({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new FFStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new FFScore({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new Versus({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new Formation({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new TeamCrawl({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new TeamBottomBar({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new TopPerformers({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new OnBench({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new MatchStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new PositionMatchStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new EdgeMatchStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
    }),
    new GoalTag({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new OtherTag({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new GameScoresTag({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new NameTag({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new LatestScoresCB({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new FFTable({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new SOFTable({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new CBTable({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new GameScoresBug({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
    }),
    new ScoreBug({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new LTScore({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new ClockTryTimeline({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CBSinBin({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),

    new PlayerCB({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),

    new AllTag({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),

    new VoiceOf({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new GuestName({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new FFStatsLoggers({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CarryingStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new AttackingStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new DefendingStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new KickPredictorGauge({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new PlayerKeyStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CBSeasonStats({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CBManual({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CBChallenges({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new CBChallengesComparison({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
    }),
    new HeroFormation({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
      continueVizPage,
    }),
    new ShotClock({
      preview,
      animate,
      update,
      animateOff,
      triggerAnimation,
      getVizPage,
      updateVizPage,
      vizSharedMemory,
      createPage,
      setPageSummary,
      vizCommand,
      continueVizPage,
    }),
  ];
}
