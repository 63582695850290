export class Substitution {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "Substitution";
    this.scene = "Substitution";
    this.data = [];
    this.page = 10000;
  }

  async preview({ options }) {}
  animate({ options }) {
    let subs = options.players
      ?.map(
        (s) =>
          s?.squad?.shirt_number +
          "," +
          s?.squad?.player?.first_name?.[0] +
          ". " +
          s?.squad?.player?.last_name
      )
      ?.join(",");

    let team = options?.game?.home_team?._id === options?.team?._id ? "1" : "2";

    let string = "";
    if (options.hia) {
      string =
        "SMMSystem_SetValue|SHMSend_InterChange|" +
        "HIA," +
        team +
        ",Sub" +
        "," +
        subs;
    } else if (options.reversal) {
      string =
        "SMMSystem_SetValue|SHMSend_InterChange|" +
        "HIA," +
        team +
        ",Reversal" +
        "," +
        subs;
    } else {
      string =
        "SMMSystem_SetValue|SHMSend_InterChange|" +
        team +
        ",1," +
        options.remaining +
        "," +
        options.total +
        "," +
        subs;
    }

    debugger;
    this.vizSharedMemory({
      shared_memory: string,
    });
  }

  animateOff({ options }) {
    this.vizSharedMemory({
      shared_memory:
        "SMMSystem_SetValue|SHMSend_InterChange|REG_InterChangeOut",
    });
  }

  update({ options }) {}
}
