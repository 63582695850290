import React, { useContext } from "react";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { DataContext } from "../../contexts/DataContext";
import Stat from "./Stat";
import { Button, ScrollBar } from "../../Theme/Hyper";
import { useState } from "react";
import useLocalStorage from "../../Hooks/useLocalStorage";
import StatSky from "./StatSky";
const Main = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 639px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const CBButton = styled(Button)`
  height: 10px;
  margin: 2px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const GraphicButton = styled(Button)`
  height: 10px;
  margin: 2px;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const names = [
  {
    id: "attacking_kicks",
    name: "Attacking kicks",
  },
  {
    id: "av_gain",
    name: "Average gain",
  },
  {
    id: "carries",
    name: "Carries",
  },
  {
    id: "clean_break",
    name: "Linebreaks",
  },
  {
    id: "drop_goals",
    name: "Drop Goals",
  },
  {
    id: "errors",
    name: "Errors",
  },
  {
    id: "forty_twenty",
    name: "40/20s",
  },
  {
    id: "game_id",
    name: "",
  },
  {
    id: "goals",
    name: "",
  },
  {
    id: "kicks_in_gen_play",
    name: "Kicks in general play",
  },
  {
    id: "marker_tackles",
    name: "Marker tackles",
  },
  {
    id: "metres",
    name: "Metres",
  },
  {
    id: "missed_goals",
    name: "",
  },
  {
    id: "missed_tackles",
    name: "Missed tackles",
  },
  {
    id: "offload",
    name: "Offloads",
  },
  {
    id: "penalties",
    name: "Penalties conceded",
  },
  {
    id: "player_id",
    name: "",
  },
  {
    id: "red_card",
    name: "",
  },
  {
    id: "run_from_dummy_half",
    name: "Runs from dummy half",
  },
  {
    id: "tackle_busts",
    name: "Tackle busts",
  },
  {
    id: "tackles",
    name: "Tackles",
  },
  {
    id: "team_id",
    name: "",
  },
  {
    id: "tries",
    name: "Tries",
  },
  {
    id: "try_assists",
    name: "Try assists",
  },
  {
    id: "yellow_card",
    name: "",
  },
  {
    id: "break_assist",
    name: "Linebreak assists",
  },
  {
    id: "conversions",
    name: "Conversions",
  },
  {
    id: "dummy_half_metres",
    name: "Dummy half metres",
  },
  {
    id: "hit_up_metres",
    name: "Hit up metres",
  },
  {
    id: "hit_ups",
    name: "Hit ups",
  },
  {
    id: "kick_from_hand_metres",
    name: "Kick from hand metres",
  },
  {
    id: "kick_return_metres",
    name: "Kick return metres",
  },
  {
    id: "kick_returns",
    name: "Kick returns",
  },
  {
    id: "minutes_player_total",
    name: "Minutes",
  },
  {
    id: "missed_conversions",
    name: "",
  },
  {
    id: "missed_penalty_goals",
    name: "",
  },
  {
    id: "on_report",
    name: "",
  },
  {
    id: "passes",
    name: "Passes",
  },
  {
    id: "penalty_goals",
    name: "",
  },
  {
    id: "twenty_forty",
    name: "20/40s",
  },
  {
    id: "ruck_infringements",
    name: "Ruck infringements",
  },
  {
    id: "set_restart_conceded_10m_offside",
    name: "Six agains conceded (10m offside)",
  },
  {
    id: "set_restart_won",
    name: "Six agains won",
  },
  {
    id: "kick_defused",
    name: "Kicks defused",
  },
  {
    id: "kick_not_defused",
    name: "Kicks not defused",
  },
  {
    id: "ball_run_metres",
    name: "Carry metres",
  },
  {
    id: "post_contact_metres",
    name: "Post contact metres",
  },
  {
    id: "one_on_one_steal",
    name: "One-on-one steal",
  },
  {
    id: "one_point_field_goal",
    name: "",
  },
  {
    id: "two_point_field_goal",
    name: "",
  },
  {
    id: "tackle_efficiency",
    name: "Tackle success",
  },
  {
    id: "goal_line_dropout",
    name: "Goal line dropouts",
  },
  {
    id: "missed_field_goals",
    name: "",
  },
  {
    id: "ptb_opp_20m",
    name: "PTB's in opp. 20",
  },
  {
    id: "kick_receipt",
    name: "Kick receipts",
  },
  {
    id: "minutes_played_total",
    name: "Minutes played",
  },
  {
    id: "points",
    name: "Points",
  },
];
export default function PlayerStats({ player, game }) {
  const { previewGraphic, graphics } = useContext(GraphicsContext);
  const [selectedStatCollection, setSelecetedStatCollection] = useLocalStorage(
    "rugby_stat_collection",
    []
  );

  return game?.home_player_stats?.[0]?.id ? (
    <PlayerStatsOval player={player} game={game} />
  ) : game?.opta_home_player_stats ? (
    <>
      <Row>
        <GraphicButton
          onClick={() => {
            setSelecetedStatCollection([]);
          }}
        >
          Clear
        </GraphicButton>

        <GraphicButton
          onClick={() => {
            let stats =
              game?.opta_home_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              ) ||
              game?.opta_away_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              ) ||
              {};

            try {
              stats = JSON.parse(JSON.stringify(stats));
              let goals = game?.home_scorers?.filter(
                (p) => p?.player === player.squad?._id
              );
              if (!goals || goals?.length === 0) {
                goals = game?.away_scorers?.filter(
                  (p) => p?.player === player.squad?._id
                );
              }

              stats.stats.points = getScore(game, goals)?.score || 0;
            } catch (err) {}

            function getScore(game, goals) {
              let try_points =
                game?.competition?.competition_type === "union" ? 5 : 4;
              let conversion_points =
                game?.competition?.competition_type === "union" ? 2 : 2;
              let penalty_kick_points =
                game?.competition?.competition_type === "union" ? 3 : 2;
              let penalty_try_points =
                game?.competition?.competition_type === "union" ? 7 : 4;
              let drop_goal_points =
                game?.competition?.competition_type === "union" ? 3 : 1;

              let score =
                (goals?.filter((g) => g.type === "try").length * try_points ||
                  0) +
                (goals?.filter((g) => g.type === "conversion").length *
                  conversion_points || 0) +
                (goals?.filter((g) => g.type === "penalty_kick").length *
                  penalty_kick_points || 0) +
                (goals?.filter((g) => g.type === "penalty_try").length *
                  penalty_try_points || 0) +
                (goals?.filter((g) => g.type === "drop_goal").length *
                  drop_goal_points || 0);

              return {
                score,
              };
            }
            previewGraphic("PLAYERCB", {
              player,
              game,
              team: game?.opta_home_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              )
                ? game?.home_team
                : game?.away_team,
              subline: "",
              stats: selectedStatCollection?.map((s) => {
                return {
                  name: names?.find((st) => st.id === s)?.name,
                  value:
                    parseInt(stats?.stats?.[s]) +
                    (names?.find((st) => st.id === s)?.name === "Tackle success"
                      ? "%"
                      : ""),
                };
              }),
              push_up:
                graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
            });
          }}
        >
          Build CB
        </GraphicButton>
        <GraphicButton
          onClick={() => {
            let stats =
              game?.opta_home_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              ) ||
              game?.opta_away_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              ) ||
              {};

            try {
              stats = JSON.parse(JSON.stringify(stats));
              let goals = game?.home_scorers?.filter(
                (p) => p?.player === player.squad?._id
              );
              if (!goals || goals?.length === 0) {
                goals = game?.away_scorers?.filter(
                  (p) => p?.player === player.squad?._id
                );
              }

              stats.stats.points = getScore(game, goals)?.score || 0;
            } catch (err) {}

            function getScore(game, goals) {
              let try_points =
                game?.competition?.competition_type === "union" ? 5 : 4;
              let conversion_points =
                game?.competition?.competition_type === "union" ? 2 : 2;
              let penalty_kick_points =
                game?.competition?.competition_type === "union" ? 3 : 2;
              let penalty_try_points =
                game?.competition?.competition_type === "union" ? 7 : 4;
              let drop_goal_points =
                game?.competition?.competition_type === "union" ? 3 : 1;

              let score =
                (goals?.filter((g) => g.type === "try").length * try_points ||
                  0) +
                (goals?.filter((g) => g.type === "conversion").length *
                  conversion_points || 0) +
                (goals?.filter((g) => g.type === "penalty_kick").length *
                  penalty_kick_points || 0) +
                (goals?.filter((g) => g.type === "penalty_try").length *
                  penalty_try_points || 0) +
                (goals?.filter((g) => g.type === "drop_goal").length *
                  drop_goal_points || 0);

              return {
                score,
              };
            }

            previewGraphic("PLAYERCB", {
              player,
              game,
              team: game?.opta_home_player_stats?.find(
                (p) => p?.player === player.squad?.player?.opta_ID
              )
                ? game?.home_team
                : game?.away_team,
              subline:
                game?.competition?.variables?.player_of_the_match_text ||
                "Betfred Player of the Match",
              stats: selectedStatCollection?.map((s) => {
                return {
                  name: names?.find((st) => st.id === s)?.name,
                  value:
                    parseInt(stats?.stats?.[s]) +
                    (names?.find((st) => st.id === s)?.name === "Tackle success"
                      ? "%"
                      : ""),
                };
              }),
              push_up:
                graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
            });
          }}
        >
          Build CB POTM
        </GraphicButton>
      </Row>
      <PlayerStatsOpta
        player={player}
        game={game}
        selectedStatCollection={selectedStatCollection}
        setSelecetedStatCollection={setSelecetedStatCollection}
      />
    </>
  ) : (
    <PlayerStatsHyper player={player} game={game} />
  );
}
function PlayerStatsOpta({
  player,
  game,
  selectedStatCollection,
  setSelecetedStatCollection,
}) {
  const { previewGraphic, updateGraphic } = useContext(GraphicsContext);
  const [selectedStat, setSelectedStat] = useState();

  let stats =
    game?.opta_home_player_stats?.find(
      (p) => p?.player === player.squad?.player?.opta_ID
    ) ||
    game?.opta_away_player_stats?.find(
      (p) => p?.player === player.squad?.player?.opta_ID
    ) ||
    {};

  try {
    stats = JSON.parse(JSON.stringify(stats));
    let goals = game?.home_scorers?.filter(
      (p) => p?.player === player.squad?._id
    );
    if (!goals || goals?.length === 0) {
      goals = game?.away_scorers?.filter(
        (p) => p?.player === player.squad?._id
      );
    }

    stats.stats.points = getScore(game, goals)?.score || 0;
  } catch (err) {}

  function getScore(game, goals) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let score =
      (goals?.filter((g) => g.type === "try").length * try_points || 0) +
      (goals?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (goals?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (goals?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (goals?.filter((g) => g.type === "drop_goal").length * drop_goal_points ||
        0);

    return {
      score,
    };
  }

  return (
    <Main>
      {stats?.stats &&
        Object.keys(stats?.stats)
          ?.filter(
            (key) =>
              names?.find((s) => s.id === key) &&
              names?.find((s) => s.id === key)?.name !== ""
          )
          ?.sort((a, b) => {
            if (
              names?.find((s) => s.id === a)?.name <
              names?.find((s) => s.id === b)?.name
            ) {
              return -1;
            }
            if (
              names?.find((s) => s.id === a)?.name >
              names?.find((s) => s.id === b)?.name
            ) {
              return 1;
            }
            return 0;
          })
          ?.map((stat) => {
            return (
              <Row>
                <CBButton
                  selected={
                    selectedStatCollection?.findIndex((s) => s === stat) > -1
                  }
                  onClick={() => {
                    debugger;
                    let new_collection = [...selectedStatCollection];
                    if (new_collection?.findIndex((s) => s === stat) > -1) {
                      new_collection = new_collection?.filter(
                        (s) => s !== stat
                      );
                    } else {
                      new_collection.push(stat);
                    }
                    setSelecetedStatCollection(new_collection);
                  }}
                >
                  CB
                </CBButton>
                <StatSky
                  previewGraphic={(name, options) => {
                    previewGraphic(name, {
                      ...options,
                    });
                  }}
                  category={names?.find((s) => s.id === stat)?.name || stat}
                  value={stats?.stats?.[stat]}
                  game={game}
                  player={player}
                  selectedStat={selectedStat}
                  setSelectedStat={setSelectedStat}
                  updateGraphic={updateGraphic}
                />
              </Row>
            );
          })}
    </Main>
  );
}

function PlayerStatsHyper({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);

  let stats =
    game?.home_player_stats?.[player.squad?._id] ||
    game?.away_player_stats?.[player.squad?._id] ||
    {};
  return (
    <Main>
      {Object.keys(stats)?.map((stat) => {
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={stat}
            value={stats[stat]}
            game={game}
            player={player}
          />
        );
      })}
    </Main>
  );
}

function PlayerStatsOval({ player, game }) {
  const { previewGraphic } = useContext(GraphicsContext);

  let stats =
    game?.home_player_stats?.find((p) => p.id === player.oval_ID)?.stats ||
    game?.away_player_stats?.find((p) => p.id === player.oval_ID)?.stats ||
    {};

  function convertCat(cat) {
    const result = cat.replace(/([A-Z])/g, " $1");
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    if (finalResult.indexOf("Percent") > -1) {
      finalResult = finalResult.replace("Percent ", "");
      finalResult = finalResult + " %";
      finalResult = finalResult.replace("Tackle Made", "Tackles Made");
    }
    return finalResult;
  }
  return (
    <Main>
      {Object.keys(stats?.attack || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            setSelectedStat={() => {}}
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.attack?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.defence || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            setSelectedStat={() => {}}
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.defence?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.discipline || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.discipline?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.kicking || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.kicking?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.lineout || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.lineout?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.restart || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.restart?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
      {Object.keys(stats?.scoring || {})?.map((stat) => {
        let category = convertCat(stat);
        return (
          <Stat
            previewGraphic={(name, options) => {
              previewGraphic(name, {
                ...options,
              });
            }}
            category={category}
            value={stats?.scoring?.[stat]}
            game={game}
            player={player}
          />
        );
      })}
    </Main>
  );
}
