import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../contexts/APIContext";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import { UIContext } from "../../contexts/UIContext";
const Main = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction column;
`;
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction column;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const Scroll = styled(ScrollBar)`
  overflow-y: auto;
  height: 516px;
`;

const SplitGraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 45%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

function Pundits({ previewGraphic, game }) {
  const { getPundits } = useContext(APIContext);
  const { popout } = useContext(UIContext);
  const { graphics, animateGraphic, animateOffGraphic } =
    useContext(GraphicsContext);
  const [pundits, setPundits] = useState([]);
  useEffect(() => {
    let interval;
    getData();
    setInterval(() => {
      interval = getData();
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function getData() {
    getPundits().then((data) => {
      setPundits(data);
    });
  }

  let period = [...(game?.live_data?.periods || [])].sort(
    (a, b) => b.number - a.number
  )[0];
  return (
    <Main>
      {period.number === 2 && !period.kick_off && (
        <Row>
          <SplitGraphicButton
            onClick={() => {
              animateGraphic("ScoreBug", {
                game: game,
                status: "HT",
              });
            }}
          >
            HT Bug ON
          </SplitGraphicButton>
          <SplitGraphicButton
            onClick={() => {
              animateOffGraphic("ScoreBug", {
                game: game,
                status: "HT",
              });
            }}
          >
            HT Bug OFF
          </SplitGraphicButton>
        </Row>
      )}
      {period.number === 3 && !period.kick_off && (
        <Row>
          <SplitGraphicButton
            onClick={() => {
              animateGraphic("ScoreBug", {
                game: game,
                status: "FT",
                golden_point: period?.number >= 4,
                popout: popout?.find((p) => p?.game_id === game?._id),
              });
            }}
          >
            FT Bug ON
          </SplitGraphicButton>
          <SplitGraphicButton
            onClick={() => {
              animateOffGraphic("ScoreBug", {
                game: game,
                status: "FT",
                golden_point: period?.number >= 4,
              });
            }}
          >
            FT Bug OFF
          </SplitGraphicButton>
        </Row>
      )}
      {period.number > 3 && !period.kick_off && (
        <Row>
          <SplitGraphicButton
            onClick={() => {
              animateGraphic("ScoreBug", {
                game: game,
                status: "FT",
                golden_point: period?.number >= 4,
                popout: popout?.find((p) => p?.game_id === game?._id),
              });
            }}
          >
            FT Bug ON
          </SplitGraphicButton>
          <SplitGraphicButton
            onClick={() => {
              animateOffGraphic("ScoreBug", {
                game: game,
                status: "FT",
                golden_point: period?.number >= 4,
              });
            }}
          >
            FT Bug OFF
          </SplitGraphicButton>
        </Row>
      )}
      <Scroll>
        {pundits
          ?.filter(
            (p) => game?.pundits?.findIndex((pun) => pun?._id === p?._id) > -1
          )
          ?.map((pundit) => {
            return (
              <Column>
                <span style={{ marginTop: "11px" }}>
                  {pundit.first_name} {pundit.last_name}
                </span>
                <GraphicButton
                  onClick={() => {
                    previewGraphic("VoiceOf", {
                      player: pundit,
                      push_up:
                        graphics?.find((g) => g?.name === "CLOCK")?.onAir ||
                        graphics?.find((g) => g?.name === "ScoreBug")?.onAir,
                    });
                  }}
                >
                  VOICE OF
                </GraphicButton>

                {pundit.stories
                  ?.filter((s) => s.visible)
                  ?.map((story) => {
                    return (
                      <GraphicButton
                        onClick={() => {
                          previewGraphic("GuestName", {
                            player: pundit,
                            story: story,
                          });
                        }}
                      >
                        <span>
                          {story?.title && story?.title !== ""
                            ? story?.title?.toUpperCase()
                            : story?.story?.toUpperCase()}
                        </span>
                      </GraphicButton>
                    );
                  })}
              </Column>
            );
          })}
      </Scroll>
    </Main>
  );
}

export default Pundits;
