export class PenaltyMap {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "PenaltyMap";
    this.scene = "Penalty Map";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Team Type",
      value: {
        text:
          options.team?._id === options.game?.home_team?._id
            ? "home"
            : options.team?._id === options.game?.away_team?._id
            ? "away"
            : "",
      },
    });

    this.data.push({
      name: "Team Name",
      value: {
        text: options.team?.name || "",
      },
    });

    this.data.push({
      name: "Team Badge",
      value: {
        image: "${user}" + options.team?.variables?.badge || "",
      },
    });
    this.data.push({
      name: "Team Secondary Badge",
      value: {
        image: "${user}" + options.team?.variables?.alt_badge || "",
      },
    });
    this.data.push({
      name: "Team Third Badge",
      value: {
        image: "${user}" + options.team?.variables?.third_badge || "",
      },
    });
    let kit =
      options.team?._id === options.game?.home_team?._id
        ? options.game?.home_team_kit
        : options.game?.away_team_kit;

    this.data.push({
      name: "Team Kit BG Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour",
      value: {
        colour: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Team Kit BG Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Team Kit Text Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Team Kit Text Colour String",
      value: {
        text: options.team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Total Penalties Conceded",
      value: {
        text: options.data?.events?.length,
      },
    });

    new Array(20).fill(1)?.map((_, index) => {
      let row = index + 1;
      let event = options.data?.events?.[index];
      if (event) {
        this.data.push({
          name: "Penalty X " + row,
          value: {
            text: event.startX || "",
          },
        });
        this.data.push({
          name: "Penalty Y " + row,
          value: {
            text: event.startY || "",
          },
        });
        this.data.push({
          name: "Penalty Period " + row,
          value: {
            text:
              event.period?.id === 20 ? 1 : event.period?.id === 30 ? 2 : "",
          },
        });
      }
    });

    let Home_kit = options.game?.home_team_kit;

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game?.home_team?.variables?.[Home_kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game?.home_team?.variables?.[Home_kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text: options.game?.home_team?.variables?.[Home_kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text:
          options.game?.home_team?.variables?.[Home_kit + "_kit_text"] || "",
      },
    });

    let away_kit = options.game?.away_team_kit;

    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game?.away_team?.variables?.[away_kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game?.away_team?.variables?.[away_kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text: options.game?.away_team?.variables?.[away_kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text:
          options.game?.away_team?.variables?.[away_kit + "_kit_text"] || "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
