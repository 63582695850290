export class ActionAreas {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "ActionAreas";
    this.scene = "Action Areas";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Type",
      value: {
        text: options.type || "",
      },
    });

    this.data.push({
      name: "Period",
      value: {
        text: options.period || "",
      },
    });
    let kit = options.game?.home_team_kit;

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour: options.game?.home_team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour: options.game?.home_team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Home Team Kit BG Colour String",
      value: {
        text: options.game?.home_team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour String",
      value: {
        text: options.game?.home_team?.variables?.[kit + "_kit_text"] || "",
      },
    });
    kit = options.game?.away_team_kit;
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour: options.game?.away_team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour: options.game?.away_team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Away Team Kit BG Colour String",
      value: {
        text: options.game?.away_team?.variables?.[kit + "_kit_bg"] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour String",
      value: {
        text: options.game?.away_team?.variables?.[kit + "_kit_text"] || "",
      },
    });

    this.data.push({
      name: "Home Possession Zone A",
      value: {
        text:
          options.game?.home_team_stats?.possession?.percentPossessionZoneA ||
          "",
      },
    });

    this.data.push({
      name: "Home Possession Zone B",
      value: {
        text:
          options.game?.home_team_stats?.possession?.percentPossessionZoneB ||
          "",
      },
    });

    this.data.push({
      name: "Home Possession Zone C",
      value: {
        text:
          options.game?.home_team_stats?.possession?.percentPossessionZoneC ||
          "",
      },
    });

    this.data.push({
      name: "Home Possession Zone D",
      value: {
        text:
          options.game?.home_team_stats?.possession?.percentPossessionZoneD ||
          "",
      },
    });

    this.data.push({
      name: "Away Possession Zone A",
      value: {
        text:
          options.game?.away_team_stats?.possession?.percentPossessionZoneA ||
          "",
      },
    });

    this.data.push({
      name: "Away Possession Zone B",
      value: {
        text:
          options.game?.away_team_stats?.possession?.percentPossessionZoneB ||
          "",
      },
    });

    this.data.push({
      name: "Away Possession Zone C",
      value: {
        text:
          options.game?.away_team_stats?.possession?.percentPossessionZoneC ||
          "",
      },
    });

    this.data.push({
      name: "Away Possession Zone D",
      value: {
        text:
          options.game?.away_team_stats?.possession?.percentPossessionZoneD ||
          "",
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
