export class ShotClock {
  constructor({
    animate,
    preview,
    update,
    animateOff,
    getVizPage,
    updateVizPage,
    vizSharedMemory,
  }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.getVizPage = getVizPage;
    this.updateVizPage = updateVizPage;
    this.vizSharedMemory = vizSharedMemory;
    this.name = "ShotClock";
    this.scene = "ShotClock";
    this.data = [];
  }

  async preview({ options }) {
    this.onAir = false;
  }
  animate({ options }) {
    this.onAir = true;
    debugger;
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_ShotClockActive|1",
    });
  }
  animateOff({ options }) {
    this.onAir = false;
    this.vizSharedMemory({
      shared_memory: "SMMSystem_SetValue|SHMSend_ShotClockActive|0",
    });
  }
}
