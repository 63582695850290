import React, { useContext, useEffect, useState } from "react";
import { GiAmericanFootballBall, GiRugbyConversion } from "react-icons/gi";
import styled from "styled-components";
import { APIContext } from "../../contexts/APIContext";
import moment from "moment";
import TimeEdit from "../Popup/TimeEdit";

const Split = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
`;
const Main = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const Name = styled.div`
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  min-width: 200px;
  color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#fff" : "#fff"};

  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
`;

const Middle = styled.div`
  width: 20%;
`;
const Goal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.red ? "red" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  min-width: 200px;
  white-space: nowrap;
`;

const FootballIcon = styled(GiAmericanFootballBall)`
  margin-right: 2px;
`;

const ConversionIcon = styled(GiRugbyConversion)`
  margin-right: 2px;
`;
const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

const Button = styled.div`
  display: flex;
  background-color: ${(props) => (props.red ? "#bf2d2d" : "#353a47")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 5px;
  :hover {
    background-color: #db0a41;
  }
`;
const YellowCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #fffe00;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  text-align: center;
`;
const RedCard = styled.div`
  width: 18px;
  height: 25px;
  background-color: #ff0034;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.primaryBackgroundColour};
  opacity: 0.7;
`;

function Scores({ game }) {
  const { updateGame } = useContext(APIContext);
  const [showHomeSquad, setShowHomeSquad] = useState();
  const [showAwaySquad, setShowAwaySquad] = useState();

  return (
    <Split>
      {showHomeSquad && (
        <Squad
          lineup={game?.lineup?.home}
          selectPlayer={(player) => {
            if (showHomeSquad.type === "sin_bin") {
              let newLineup = [
                ...JSON.parse(JSON.stringify(game?.lineup?.home)),
              ];

              let sin_bin = {
                ...newLineup
                  .find((l) => l.squad?._id === showHomeSquad?.squad._id)
                  .sinbins.find((l) => l._id === showHomeSquad._id),
              };

              newLineup.find(
                (l) => l.squad?._id === showHomeSquad?.squad._id
              ).sinbins = newLineup
                .find((l) => l.squad?._id === showHomeSquad?.squad._id)
                .sinbins.filter((l) => l._id !== showHomeSquad._id);

              newLineup
                .find((l) => l.squad?._id === player?.squad._id)
                .sinbins.push(sin_bin);

              updateGame({
                id: game._id,
                lineup: { home: newLineup, away: game?.lineup?.away },
              });
            } else if (showHomeSquad.type === "red_card") {
              let newLineup = [
                ...JSON.parse(JSON.stringify(game?.lineup?.home)),
              ];

              newLineup.find(
                (l) => l.squad?._id === showHomeSquad?.squad._id
              ).red_card = false;

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card = true;
              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_time = parseInt(
                newLineup.find((l) => l.squad?._id === showHomeSquad?.squad._id)
                  .red_card_time
              );

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_period = parseInt(
                newLineup.find((l) => l.squad?._id === showHomeSquad?.squad._id)
                  .red_card_period
              );

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_seconds = parseInt(
                newLineup.find((l) => l.squad?._id === showHomeSquad?.squad._id)
                  .red_card_seconds
              );

              updateGame({
                id: game._id,
                lineup: { home: newLineup, away: game?.lineup?.away },
              });
            } else {
              let newScorers = [...game.home_scorers];
              let index = newScorers.findIndex(
                (g) => g._id === showHomeSquad._id
              );
              newScorers[index] = {
                ...newScorers[index],
                player: player.squad._id,
              };
              updateGame({
                id: game._id,
                home_scorers: newScorers,
              });
            }

            setShowHomeSquad();
          }}
        />
      )}
      {!showHomeSquad && (
        <Team
          lineup={game?.lineup?.home}
          scorers={[...(game?.home_scorers || [])]}
          removeGoal={(goal) => {
            let new_scorers = game?.home_scorers?.filter(
              (s) => s._id !== goal._id
            );
            updateGame({
              id: game._id,
              home_scorers: new_scorers,
            });
          }}
          removeSinBin={(sinBin) => {
            let newLineup = [...JSON.parse(JSON.stringify(game?.lineup?.home))];

            newLineup.find((l) => l.squad?._id === sinBin?.squad._id).sinbins =
              newLineup
                .find((l) => l.squad?._id === sinBin?.squad._id)
                .sinbins.filter((l) => l._id !== sinBin._id);
            updateGame({
              id: game._id,
              lineup: { home: newLineup, away: game?.lineup?.away },
            });
          }}
          removeRedCard={(sinBin) => {
            let newLineup = [...JSON.parse(JSON.stringify(game?.lineup?.home))];

            newLineup.find(
              (l) => l.squad?._id === sinBin?.squad._id
            ).red_card = false;
            updateGame({
              id: game._id,
              lineup: { home: newLineup, away: game?.lineup?.away },
            });
          }}
          changeType={(goal) => {
            let new_scorers = [...(game?.home_scorers || [])];
            let index = new_scorers?.findIndex((g) => g?._id === goal?._id);
            if (index > -1) {
              new_scorers[index] = goal;
            }

            updateGame({
              id: game._id,
              home_scorers: new_scorers,
            });
          }}
          changeTime={(goal) => {
            try {
              if (goal?.type === "sin_bin") {
                let newLineup = [
                  ...JSON.parse(JSON.stringify(game?.lineup?.home)),
                ];

                let player = newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                );
                let index = player.sinbins.findIndex((l) => l._id === goal._id);

                if (index > -1) {
                  newLineup.find(
                    (l) => l.squad?._id === goal?.squad._id
                  ).sinbins[index].game_time_minutes = goal.game_time_minutes;
                  newLineup.find(
                    (l) => l.squad?._id === goal?.squad._id
                  ).sinbins[index].game_time_seconds = goal.game_time_seconds;
                  updateGame({
                    id: game._id,
                    lineup: { home: newLineup, away: game?.lineup?.away },
                  });
                }
              } else if (goal?.type === "red_card") {
                let newLineup = [
                  ...JSON.parse(JSON.stringify(game?.lineup?.home)),
                ];

                newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                ).red_card_time = goal.game_time_minutes;
                newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                ).red_card_seconds = goal.game_time_seconds;
                updateGame({
                  id: game._id,
                  lineup: { home: newLineup, away: game?.lineup?.away },
                });
              } else {
                let new_scorers = [...(game?.home_scorers || [])];
                let index = new_scorers?.findIndex((g) => g?._id === goal?._id);
                if (index > -1) {
                  new_scorers[index] = goal;
                }

                updateGame({
                  id: game._id,
                  home_scorers: new_scorers,
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
          setShowSquad={setShowHomeSquad}
        />
      )}
      <Middle />
      {showAwaySquad && (
        <Squad
          lineup={game?.lineup?.away}
          selectPlayer={(player) => {
            if (showAwaySquad.type === "sin_bin") {
              let newLineup = [
                ...JSON.parse(JSON.stringify(game?.lineup?.away)),
              ];

              let sin_bin = {
                ...newLineup
                  .find((l) => l.squad?._id === showAwaySquad?.squad._id)
                  .sinbins.find((l) => l._id === showAwaySquad._id),
              };

              newLineup.find(
                (l) => l.squad?._id === showAwaySquad?.squad._id
              ).sinbins = newLineup
                .find((l) => l.squad?._id === showAwaySquad?.squad._id)
                .sinbins.filter((l) => l._id !== showAwaySquad._id);

              newLineup
                .find((l) => l.squad?._id === player?.squad._id)
                .sinbins.push(sin_bin);

              updateGame({
                id: game._id,
                lineup: { away: newLineup, home: game?.lineup?.home },
              });
            } else if (showAwaySquad.type === "red_card") {
              let newLineup = [
                ...JSON.parse(JSON.stringify(game?.lineup?.away)),
              ];

              newLineup.find(
                (l) => l.squad?._id === showAwaySquad?.squad._id
              ).red_card = false;

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card = true;
              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_time = parseInt(
                newLineup.find((l) => l.squad?._id === showAwaySquad?.squad._id)
                  .red_card_time
              );

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_period = parseInt(
                newLineup.find((l) => l.squad?._id === showAwaySquad?.squad._id)
                  .red_card_period
              );

              newLineup.find(
                (l) => l.squad?._id === player?.squad._id
              ).red_card_seconds = parseInt(
                newLineup.find((l) => l.squad?._id === showAwaySquad?.squad._id)
                  .red_card_seconds
              );

              updateGame({
                id: game._id,
                lineup: { away: newLineup, home: game?.lineup?.home },
              });
            } else {
              let newScorers = [...game.away_scorers];
              let index = newScorers.findIndex(
                (g) => g._id === showAwaySquad._id
              );
              newScorers[index] = {
                ...newScorers[index],
                player: player.squad._id,
              };
              updateGame({
                id: game._id,
                away_scorers: newScorers,
              });
            }

            setShowAwaySquad();
          }}
        />
      )}
      {!showAwaySquad && (
        <Team
          lineup={game?.lineup?.away}
          scorers={[...(game?.away_scorers || [])]}
          removeGoal={(goal) => {
            let new_scorers = game?.away_scorers?.filter(
              (s) => s._id !== goal._id
            );
            updateGame({
              id: game._id,
              away_scorers: new_scorers,
            });
          }}
          removeSinBin={(sinBin) => {
            let newLineup = [...JSON.parse(JSON.stringify(game?.lineup?.away))];

            newLineup.find((l) => l.squad?._id === sinBin?.squad._id).sinbins =
              newLineup
                .find((l) => l.squad?._id === sinBin?.squad._id)
                .sinbins.filter((l) => l._id !== sinBin._id);
            updateGame({
              id: game._id,
              lineup: { away: newLineup, home: game?.lineup?.home },
            });
          }}
          removeRedCard={(sinBin) => {
            let newLineup = [...JSON.parse(JSON.stringify(game?.lineup?.away))];

            newLineup.find(
              (l) => l.squad?._id === sinBin?.squad._id
            ).red_card = false;

            updateGame({
              id: game._id,
              lineup: { away: newLineup, home: game?.lineup?.home },
            });
          }}
          changeType={(goal) => {
            let new_scorers = [...(game?.away_scorers || [])];
            let index = new_scorers?.findIndex((g) => g?._id === goal?._id);
            if (index > -1) {
              new_scorers[index] = goal;
            }

            updateGame({
              id: game._id,
              away_scorers: new_scorers,
            });
          }}
          changeTime={(goal) => {
            try {
              if (goal?.type === "sin_bin") {
                let newLineup = [
                  ...JSON.parse(JSON.stringify(game?.lineup?.away)),
                ];

                let player = newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                );
                let index = player.sinbins.findIndex((l) => l._id === goal._id);

                if (index > -1) {
                  newLineup.find(
                    (l) => l.squad?._id === goal?.squad._id
                  ).sinbins[index].game_time_minutes = goal.game_time_minutes;
                  newLineup.find(
                    (l) => l.squad?._id === goal?.squad._id
                  ).sinbins[index].game_time_seconds = goal.game_time_seconds;
                  updateGame({
                    id: game._id,
                    lineup: { away: newLineup, home: game?.lineup?.home },
                  });
                }
              } else if (goal?.type === "red_card") {
                let newLineup = [
                  ...JSON.parse(JSON.stringify(game?.lineup?.away)),
                ];

                newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                ).red_card_time = goal.game_time_minutes;

                newLineup.find(
                  (l) => l.squad?._id === goal?.squad._id
                ).red_card_seconds = goal.game_time_seconds;
                updateGame({
                  id: game._id,
                  lineup: { away: newLineup, home: game?.lineup?.home },
                });
              } else {
                let new_scorers = [...(game?.away_scorers || [])];
                let index = new_scorers?.findIndex((g) => g?._id === goal?._id);
                if (index > -1) {
                  new_scorers[index] = goal;
                }

                updateGame({
                  id: game._id,
                  away_scorers: new_scorers,
                });
              }
            } catch (err) {
              console.log(err);
            }
          }}
          setShowSquad={setShowAwaySquad}
        />
      )}
    </Split>
  );
}

function Team({
  lineup,
  scorers,
  removeGoal,
  setShowSquad,
  changeType,
  changeTime,
  removeSinBin,
  removeRedCard,
}) {
  const [localScorers, setLocalScorers] = useState([]);
  const [selectedScore, setSelectedScore] = useState();
  useEffect(() => {
    let new_scorers = [...(scorers || [])];
    for (let x = 0; x < lineup?.length; x++) {
      for (let y = 0; y < lineup?.[x]?.sinbins?.length; y++) {
        new_scorers.push({
          ...lineup?.[x],
          ...lineup?.[x]?.sinbins?.[y],
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.sinbins?.[y]?.game_time_minutes,
            seconds: lineup?.[x]?.sinbins?.[y]?.game_time_seconds,
          }),
          type: "sin_bin",
        });
      }
      if (lineup?.[x]?.red_card) {
        debugger;
        new_scorers.push({
          ...lineup?.[x],
          game_time_minutes: lineup?.[x]?.red_card_time,
          game_time_seconds: lineup?.[x]?.red_card_seconds || 0,
          period: lineup?.[x]?.red_card_period,
          player: lineup?.[x]?.squad?._id,
          time: moment.duration({
            minutes: lineup?.[x]?.red_card_time,
            seconds: lineup?.[x]?.red_card_seconds || 0,
          }),
          type: "red_card",
        });
      }
    }
    new_scorers = new_scorers?.sort((a, b) => {
      if (a?.period < b?.period) {
        return -1;
      } else if (a?.period > b?.period) {
        return 1;
      }
      if (a?.game_time_minutes < b?.game_time_minutes) {
        return -1;
      } else if (a?.game_time_minutes > b?.game_time_minutes) {
        return 1;
      }
      if (a?.game_time_seconds < b?.game_time_seconds) {
        return -1;
      } else if (a?.game_time_seconds > b?.game_time_seconds) {
        return 1;
      }
    });
    setLocalScorers(new_scorers);
  }, [lineup, scorers]);

  return (
    <Main>
      {localScorers?.map((scorer) => {
        let player = lineup?.find((p) => p?.squad?._id === scorer?.player);

        return (
          <Row>
            <Name>
              {player?.squad?.player?.first_name}{" "}
              {player?.squad?.player?.last_name}
              {!player && (
                <Button
                  onClick={() => {
                    setShowSquad(scorer);
                  }}
                >
                  Assign to player
                </Button>
              )}
            </Name>
            {scorer?.type === "sin_bin" && (
              <Goal>
                <YellowCard />
              </Goal>
            )}
            {scorer?.type === "red_card" && (
              <Goal>
                <RedCard />
              </Goal>
            )}
            {(scorer?.type === "try" || scorer?.type === "penalty_try") && (
              <Goal
                draggable="true"
                red={scorer?.own_goal}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <FootballIcon />

                {scorer.type === "penalty_try" && "P"}
              </Goal>
            )}
            {scorer?.type === "no_try" && (
              <span style={{ minWidth: "200px" }}>No Try</span>
            )}
            {scorer?.type === "conversion" && (
              <Goal
                draggable="true"
                red={scorer?.own_goal}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <ConversionIcon />

                {scorer.type === "missed_conversion" && "Missed"}
              </Goal>
            )}
            {scorer?.type === "missed_conversion" && (
              <Goal
                draggable="true"
                red={true}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <ConversionIcon />
                {"Missed"}
              </Goal>
            )}
            {scorer?.type === "penalty_kick" && (
              <Goal
                draggable="true"
                red={false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <ConversionIcon />
                {"Penalty Kick"}
              </Goal>
            )}
            {scorer?.type === "missed_penalty_kick" && (
              <Goal
                draggable="true"
                red={true}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <ConversionIcon />
                {"Missed PK"}
              </Goal>
            )}
            {scorer?.type === "drop_goal" && (
              <Goal
                draggable="true"
                red={false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
                onMouseDown={(e) => {}}
              >
                <ConversionIcon />
                {"DG"}
              </Goal>
            )}
            <Button
              onClick={() => {
                setSelectedScore(scorer);
              }}
            >
              {scorer?.game_time_minutes?.toString()?.padStart(2, "0")}:
              {scorer?.game_time_seconds?.toString()?.padStart(2, "0")}
            </Button>
            <Actions>
              {scorer?.type === "try" && (
                <Button
                  onClick={() => {
                    let new_scorer = { ...scorer };
                    new_scorer.type = "penalty_try";
                    changeType(new_scorer);
                  }}
                >
                  Change to penalty
                </Button>
              )}
              {scorer?.type === "penalty_try" && (
                <Button
                  onClick={() => {
                    let new_scorer = { ...scorer };
                    new_scorer.type = "try";
                    changeType(new_scorer);
                  }}
                >
                  Change to try
                </Button>
              )}
              {player && (
                <Button
                  onClick={() => {
                    setShowSquad(scorer);
                  }}
                >
                  Change player
                </Button>
              )}

              {scorer?.type !== "sin_bin" && scorer?.type !== "red_card" && (
                <Button
                  onClick={() => {
                    removeGoal(scorer);
                  }}
                >
                  Remove
                </Button>
              )}
              {scorer?.type === "sin_bin" && (
                <Button
                  onClick={() => {
                    removeSinBin(scorer);
                  }}
                >
                  Remove
                </Button>
              )}
              {scorer?.type === "red_card" && (
                <Button
                  onClick={() => {
                    removeRedCard(scorer);
                  }}
                >
                  Remove
                </Button>
              )}
            </Actions>
          </Row>
        );
      })}
      {selectedScore && (
        <TimeEdit
          title="Edit time"
          score={selectedScore}
          onUpdate={({ minutes, seconds }) => {
            let newScore = { ...selectedScore };
            newScore.game_time_minutes = parseInt(minutes);
            newScore.game_time_seconds = parseInt(seconds);
            changeTime(newScore);
          }}
          onClose={() => {
            setSelectedScore();
          }}
        />
      )}
    </Main>
  );
}

function Squad({ lineup, selectPlayer }) {
  return (
    <Main>
      {lineup?.map((player) => {
        return (
          <Row>
            <Name>
              {player?.squad?.player?.first_name}{" "}
              {player?.squad?.player?.last_name}
            </Name>

            <Actions>
              <Button
                onClick={() => {
                  selectPlayer(player);
                }}
              >
                Select
              </Button>
            </Actions>
          </Row>
        );
      })}
    </Main>
  );
}

export default Scores;
