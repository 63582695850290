import React, { useEffect } from "react";
import Pitch from "../../Images/PitchPredictor.png";
import styled from "styled-components";
import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
const GraphicButton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #181d25;
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  cursor: pointer;
  :hover {
    background-color: #db0a41;
  }
`;

export const Button = styled.div`
  background-color: ${(props) => (props.green ? "#0adb93" : "#353a47")};
  color: ${(props) => (props.green ? "#000" : "#fff")};
  border-radius: 4px;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 5px;
`;
const Container = styled.div`
  position: relative;
  overflow: hidden;
`;
const Dot = styled.div`
  width: 15px;
  height: 15px;
  background-color: gray;
  border-radius: 50%;
  position: absolute;
  top: 98px;
  pointer-events: none;
`;
const PitchImage = styled.img`
  width: 300px;
`;
const Distance = styled.div`
  color: #0dd6f3;
  position: absolute;
  top: 140px;
  left: 6px;
  text-align: center;
  font-size: 30px;
  width: 92px;
`;
const Probability = styled.div`
  color: #0dd6f3;
  position: absolute;
  top: 140px;
  left: 106px;
  text-align: center;
  font-size: 30px;
  width: 92px;
`;
const Angle = styled.div`
  color: #0dd6f3;
  position: absolute;
  top: 140px;
  left: 208px;
  text-align: center;
  font-size: 30px;
  width: 92px;
`;
const Row = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
`;
const TOP = 25;
const twenty_two = 93;
function PitchPredictor({
  player,
  playerId,
  teamId,
  gameId,
  previewGraphic,
  game,
}) {
  const { getKickPredictor, getPlayerKicks } = useContext(DataContext);
  const [tempX, setTempX] = useState(0);
  const [x, setX] = useState(0);
  const [tempY, setTempY] = useState(0);
  const [y, setY] = useState(0);
  const [data, setData] = useState({});
  const [playerData, setPlayerData] = useState({});
  const [distance, setDistance] = useState(0);
  const [type, setType] = useState("C");
  useEffect(async () => {
    let playerdata = await getPlayerKicks({ playerId, teamId, gameId });
    setPlayerData(playerdata);
  }, [playerId, teamId, gameId]);
  async function getKick(x, y, t) {
    let location = parseInt((70 / 300) * x);
    let top = parseInt(100 - (20 / (twenty_two - TOP)) * (y - TOP));
    setDistance(top);
    let data = await getKickPredictor({ type: t, x: top, y: location });
    setData(data);
  }
  return (
    <>
      <Row>
        <Button
          green={type === "C"}
          onClick={() => {
            setType("C");
            getKick(x, y, "C");
          }}
        >
          Conv
        </Button>
        <Button
          green={type === "P"}
          onClick={() => {
            setType("P");
            getKick(x, y, "P");
          }}
        >
          Penalty
        </Button>
        <Button
          green={type === "D"}
          onClick={() => {
            setType("D");
            getKick(x, y, "D");
          }}
        >
          Drop
        </Button>
      </Row>
      <Container>
        <PitchImage
          src={Pitch}
          alt="pitch"
          onMouseMove={(e) => {
            var rect = e.target.getBoundingClientRect();
            var x = e.clientX - rect.left; //x position within the element.
            var y = e.clientY - rect.top; //y position within the element.
            x = x - 7;
            y = y - 7;
            setTempY(y);
            setTempX(x);
          }}
          onClick={() => {
            getKick(tempX, tempY, type);
            setX(tempX);
            setY(tempY);
          }}
        />
        <Dot style={{ left: x + "px", top: y + "px" }} />
        {playerData?.events?.map((ev) => {
          let outcome =
            ev.properties?.find((p) => p.propertyGroupId === 39)?.propertyId ??
            "";

          return (
            <>
              <Dot
                style={{
                  backgroundColor:
                    outcome === 211 ? "red" : outcome === 212 ? "green" : "",
                  left: parseInt(ev.startY / (70 / 300)) + "px",
                  top:
                    parseInt(ev.startX / (22 / (twenty_two - TOP)) + TOP) +
                    "px",
                }}
              />
            </>
          );
        })}
        <Distance>{parseInt(data?.distanceToPosts || 0)}m</Distance>
        <Probability>
          {parseInt(((data?.kickSuccessProbability || 0) / 1) * 100)}%
        </Probability>
        <Angle>{parseInt(data?.angleToPosts || 0)}°</Angle>
      </Container>
      <Row>
        <GraphicButton
          onClick={() => {
            previewGraphic("Kick Map", {
              player: player,
              subtext: player?.team?.name,
              team: player?.team,
              game,
              show_number: false,
              data: playerData,
              kick_data: data,
            });
          }}
        >
          Kick Predictor
        </GraphicButton>
      </Row>
    </>
  );
}

export default PitchPredictor;
