import { useContext, useState } from "react";
import { ScrollBar } from "../../../Theme/Hyper";
import { APIContext } from "../../../contexts/APIContext";
import styled from "styled-components";
import { DataContext } from "../../../contexts/DataContext";

const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 40px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;

const GraphicsList = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  max-height: 560px;
  overflow-y: auto;
  width: 100%;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;
export default function EPCRStandings({ previewGraphic, game }) {
  const { getTeams } = useContext(APIContext);
  const { getStandings } = useContext(DataContext);
  const [mini, setMini] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <Row>
        <GraphicButton
          style={{ width: "45%" }}
          green={mini === false}
          onClick={() => {
            setMini(false);
          }}
        >
          FF
        </GraphicButton>
        <GraphicButton
          style={{ width: "45%" }}
          green={mini === true}
          onClick={() => {
            setMini(true);
          }}
        >
          Mini
        </GraphicButton>
      </Row>
      {game?.competition?.name === "Champions Cup" && (
        <GraphicsList>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 1",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 1")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 1
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 2",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 2")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 2
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 3",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 3")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 3
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 4",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 4")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 4
          </GraphicButton>
        </GraphicsList>
      )}
      {game?.competition?.name !== "Champions Cup" && (
        <GraphicsList>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 1",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 1")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 1
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 2",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 2")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 2
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 3",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 3")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 3
          </GraphicButton>
          <GraphicButton
            onClick={async () => {
              let teams = await getTeams({});
              let data = await getStandings({
                compId: game?.competition?.oval_ID,
                seasonId: game?.season?.oval_ID,
              });
              let table = { data: data };

              previewGraphic("Standings", {
                title: "POOL 4",
                data: table?.data?.groups
                  ?.filter((d) => d.name === "Pool 4")
                  ?.sort((a, b) => {
                    return a?.position - b?.position;
                  })
                  ?.map((row) => {
                    return {
                      ...row,
                      teamName: row?.team?.name,
                      P: row?.played || 0,
                      W: row?.won || 0,
                      L: row?.lost || 0,
                      D: row?.drawn || 0,
                      DIFF: row?.pointsDiff || 0,
                      PTS: row?.points || 0,
                    };
                  }),
                teams,
                mini,
              });
            }}
          >
            Pool 4
          </GraphicButton>
        </GraphicsList>
      )}
    </div>
  );
}
